/* eslint-disable no-else-return */
/* eslint-disable  react/no-unused-class-component-methods */

import React from 'react';
import { Textarea } from '@salesforce/design-system-react';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import { AUDIENCE_TYPE, CONTACT_ID } from './TestSendConstants';
import SelectOptionTable from './select-option-table';
import { fetchAudienceLists, fetchContactKeysForDeviceId, fetchSystemTokenForContactKey } from '../../../actions/contacts';
import DESelector from './de-selector';

class TestInputContent extends React.Component {
	constructor (props) {
		super(props);
		this.contactId = this.props.contactId;
		this.i18n = this.props.i18n;
		this.columns = [
			{
				columnName: this.i18n.get('test_send_contact_lists_column_name'),
				columnProperty: 'name',
				sortable: true,
				width: '7rem',
				primary: true
			},
			{
				columnName: this.i18n.get('test_send_contact_lists_column_type'),
				columnProperty: 'type',
				sortable: true,
				width: '3rem'
			},
			{
				columnName: this.i18n.get('test_send_contact_lists_column_count'),
				columnProperty: 'count',
				sortable: true,
				width: '4rem'
			},
			{
				columnName: this.i18n.get('test_send_contact_lists_column_created'),
				columnProperty: 'createdDate',
				sortable: true,
				width: '7rem'
			},
			{
				columnName: this.i18n.get('test_send_contact_lists_column_modified'),
				columnProperty: 'lastUpdated',
				sortable: true,
				width: '7rem'
			}
		];
		this.state = {
			items: [],
			audienceType: AUDIENCE_TYPE.CONTACT_LIST,
			dropDownLabel: this.i18n.get('test_send_input_contact_list'),
			init: true
		};
		this.changeChild = React.createRef();
		this.updateFooterButton = this.props.updateFooterButton;
		this.updateSystemTokenFromDeviceId = this.props.updateSystemTokenFromDeviceId;
		this.ContactKeysPerDeviceIDLimit = 50;
		this.MaxContactsInList = 50;
		this.appId = this.props.appId;
		if (this.contactId === CONTACT_ID.CONTACT_LIST) {
			this.fetchAudienceLists();
		}
	}

	fetchAudienceLists = () => {
		fetchAudienceLists(this.MaxContactsInList).then((results) => {
			if ('err' in results) {
				this.setState({ items: [], init: false, err: results.err });
			} else if (this.changeChild.current) {
				this.setState({ items: results.items, init: false, err: null });
			}
		});
	};

	valueIsNotNull = (value) => value.definitionKey !== undefined && value.innerValue !== undefined && value.innerValue.length !== 0;

	findSystemToken = (responseItems) => {
		// avoiding forEach for early exit.
		for (let i = 0; i < responseItems.length; i++) {
			const item = responseItems[i];
			if (item.values !== undefined && item.values.length !== 0) {
				const { values } = item;
				let deviceId = null; let appId = null; let
					systemToken = null;
				for (let j = 0; j < values.length; j++) {
					const value = values[j];
					if (this.valueIsNotNull(value)) {
						if (value.definitionKey === 'PushAppID' && value.innerValue === this.appId) {
							appId = value.innerValue;
						}
						if (value.definitionKey === 'PushDeviceID' && value.innerValue === this.deviceId) {
							deviceId = value.innerValue;
						}
						if (value.definitionKey === 'SystemToken') {
							systemToken = value.innerValue;
						}
						if (appId !== null && deviceId !== null && systemToken !== null) {
							this.systemToken = systemToken;
							break;
						}
					}
				}
				if (this.systemToken !== null) {
					break;
				}
			}
		}
	};

	getSystemTokenForContactKey = () => {
		const key = this.contactKeys[this.keyIdx];

		fetchSystemTokenForContactKey(key).then((items) => {
			if ('errorcode' in items) {
				this.updateSystemTokenFromDeviceId(null, items.message);
			}	else {
				this.findSystemToken(items);
				if (this.systemToken === null && this.keyIdx !== this.contactKeys.length) {
					this.keyIdx += 1;
					this.getSystemTokenForContactKey();
				} else {
					this.updateSystemTokenFromDeviceId(this.systemToken, null);
				}
			}
		});
	};

	fetchSystemTokenForDeviceId = (deviceID) => {
		this.deviceId = deviceID;
		fetchContactKeysForDeviceId(deviceID).then((contactKeys) => {
			if ('errorcode' in contactKeys) {
				this.updateSystemTokenFromDeviceId(null, contactKeys.message);
			}	else if (contactKeys.contactKeys.length === 0 || contactKeys.contactKeys.length > this.ContactKeysPerDeviceIDLimit) {
				this.updateSystemTokenFromDeviceId(null, null);
			} else {
				this.contactKeys = contactKeys.contactKeys;
				this.keyIdx = 0;
				this.getSystemTokenForContactKey();
				this.systemToken = null;
			}
		});
	};

	getLabelForContactType = () => {
		switch (this.contactId) {
			case CONTACT_ID.SYSTEM_TOKEN:
				return this.i18n.get('test_send_system_token_placeholder');
			case CONTACT_ID.DEVICE_ID:
				return this.i18n.get('test_send_device_id_placeholder');
			case CONTACT_ID.CONTACT_KEY:
				return this.i18n.get('test_send_contact_key_placeholder');
			default:
				return '';
		}
	};

	onAudienceSelect = (event) => {
		if (this.state.audienceType !== event.value) {
			this.setState({
				audienceType: event.value,
				dropDownLabel: event.label
			});
			this.updateFooterButton();
		}
	};

	render () {
		if (this.contactId !== CONTACT_ID.CONTACT_LIST) {
			return (
				<Textarea
					aria-describedby="error-1"
					id="contact-id-input"
					name="required-textarea-error"
					label={this.getLabelForContactType()}
					required
					errorText=""
					placeholder={this.i18n.get('test_send_area_placeholder')}
					onInput={this.props.testInputHandler}
					defaultValue={this.props.inputValue}
				/>
			);
		} else {
			let template;
			if (this.state.audienceType === AUDIENCE_TYPE.CONTACT_LIST) {
				template = (
					<SelectOptionTable
						columnHeaders={this.columns}
						items={this.state.items}
						id="select-audience-list"
						ref={this.changeChild}
						loader={this.state.init}
						callbackValue={this.props.contactListSelectedOption}
						err={this.state.err}
						i18n={this.props.i18n}
					/>
				);
			}	else {
				template = (
					<DESelector
						selectedOption={this.props.deSelectedOption}
						i18n={this.i18n}
					/>
				);
			}
			return (
				<div>
					<Dropdown
						align="left"
						iconCategory="utility"
						iconName="down"
						iconPosition="right"
						label={this.state.dropDownLabel}
						options={[
							{ label: this.i18n.get('test_send_input_contact_list'), value: AUDIENCE_TYPE.CONTACT_LIST },
							{ label: this.i18n.get('test_send_input_data_extension'), value: AUDIENCE_TYPE.DATA_EXTENSION }
						]}
						id="audience-selector-dropdown"
						onSelect={this.onAudienceSelect}
					/>
					{template}
				</div>
			);
		}
	}
}

export default TestInputContent;
